<template>
	<span>
		<v-layout fill-height justify-space-around column>
			<v-flex scroll-y px-5 mx-1>
				<TeamManagerLists
					:accounting-firm="accountingFirm" :accounting-team="accounting_team" :loading="loading"
					:roles="roles" :is-accountant-admin="isAccountantAdmin" :user="user" :clients="clients"
					@update="openDialogUpdate($event)" @add="addAccountant($event)" @delete="openDialogDelete($event)" />
			</v-flex>
		</v-layout>
		<!-- DIALOG UPDATE -->
		<TeamManagerDialogUpdate
:dialog-update="dialogUpdate" :accountant="accountant" :accounting-firm="accountingFirm"
			:roles="roles" :clients="clients" :is-accountant-admin="isAccountantAdmin" :user="user"
			@close="dialogUpdate = false" />

		<!-- DIALOG DELETE -->
		<TeamManagerDialogDelete
:dialog-delete="dialogDelete" :accountant="accountant" :accounting-firm="accountingFirm"
			:is-accountant-admin="isAccountantAdmin" :user="user" @close="dialogDelete = false"
			@delete="deleteAccountant($event)" />
	</span>
</template>
<script>
import { mapState } from 'vuex'
import TeamManagerModuleGuard from '@/mixins/ModulesGuards/TeamManager/TeamManagerModuleGuard'

export default {
	name: 'UserManagementSection',
	components: {
		TeamManagerDialogDelete: () => ({
			component: import('@/components/TeamManager/TeamManagerDialogDelete')
		}),
		TeamManagerDialogUpdate: () => ({
			component: import('@/components/TeamManager/TeamManagerDialogUpdate')
		}),
		TeamManagerLists: () => ({
			component: import('@/components/TeamManager/TeamManagerLists')
		})
	},
	mixins: [TeamManagerModuleGuard],
	data () {
		return {
			accountant: {},
			accounting_team: [],
			currentAccountant: {},
			dialogDelete: false,
			dialogUpdate: false,
			loading: true,
			roles: []
		}
	},
	computed: {
		...mapState({
			accountingFirm: state => state.accountingFirm.selected,
			clients: state => state.clients.data,
			user: state => state.user.data,
			isAccountantAdmin: state => state.accountingFirm?.selected?.isAccountantAdmin
		})
	},
	watch: {
		accounting_team: function (val) {
			if (this.currentAccountant.id) {
				const id = this.currentAccountant.id
				this.currentAccountant = val.find(accountant => accountant.id === id)
			}
		},
		accountingFirm: {
			handler: function (newValue, oldValue) {
				if (newValue && (!oldValue || newValue != oldValue)) {
					this.loadClients()
				}
			}
		}
	},
	mounted () {
		window.addEventListener(
			'keydown',
			(e => {
				e = e || window.event
				if (('key' in e && (e.key === 'Escape' || e.key === 'Esc')) || e.keyCode === 27) {
					this.dialogDelete = false
					this.dialogUpdate = false
				}
			}).bind(this)
		)
		if (this.accountingFirm) {
			this.loadClients()
		}
	},
	methods: {
		addAccountant: function (accountant) {
			accountant.role = accountant.role.name
			this.accounting_team.push(accountant)
		},
		deleteAccountant: function (accountant) {
			this.accounting_team = this.accounting_team.filter(u => u.id != accountant.id)
			this.dialogDelete = false
		},
		loadClients: function () {
			this.loading = true
			this.currentAccountant = {}
			const accountingFirmId = this.accountingFirm.id

			Promise.all([this.service.getClients(accountingFirmId)])
				.then(() => {
					this.service.getRoles().then(roles => {
						this.roles = roles
					})
				})
				.then(() => {
					this.service
						.getAccountants(this.accountingFirm.id)
						.then(res => {
							this.accounting_team = res
						})
						.finally(() => {
							this.loading = false
						})
				})
		},
		openDialogDelete: function (accountant) {
			this.accountant = accountant
			this.dialogDelete = true
		},
		openDialogUpdate: function (accountant) {
			this.accountant = accountant
			this.dialogUpdate = true
		}
	}
}
</script>
